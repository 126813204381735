import axios from 'axios';

// ตั้งค่า Base API URL
const API_URL = 'https://www.arifootball.co.th/api';

// สร้างอินสแตนซ์ Axios
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// เพิ่ม Interceptor เพื่อแนบ Token ใน Request Headers
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['x-auth-token'] = token; // ใช้ 'x-auth-token' สำหรับการตรวจสอบสิทธิ์
    }
    return config;
  },
  (error) => Promise.reject(error) // จัดการข้อผิดพลาด
);

// เพิ่ม Interceptor สำหรับจัดการ Response Error
api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API Error:', error.response?.data?.message || error.message);
    return Promise.reject(error);
  }
);

/// **Authentication APIs**
export const login = async (email, password) => {
  try {
    const response = await api.post('/users/login', { email, password });
    if (response.data.token) {
      localStorage.setItem('token', response.data.token); // เก็บ Token ไว้ใน Local Storage
    }
    return response.data;
  } catch (error) {
    console.error('Login Error:', error.response?.data?.message || error.message);
    throw error;
  }
};

export const register = async (userData) => {
  try {
    const response = await api.post('/users/register', userData);
    if (response.data.token) {
      localStorage.setItem('token', response.data.token); // เก็บ Token ไว้ใน Local Storage
    }
    return response.data;
  } catch (error) {
    console.error('Register Error:', error.response?.data?.message || error.message);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('token'); // ลบ Token ออกจาก Local Storage
};

export const getProfile = async () => {
  try {
    const response = await api.get('/users/profile');
    return response.data;
  } catch (error) {
    console.error('Get Profile Error:', error.response?.data?.message || error.message);
    throw error;
  }
};

export const updateProfile = async (userData) => {
  try {
    const response = await api.put('/users/profile', userData);
    return response.data;
  } catch (error) {
    console.error('Update Profile Error:', error.response?.data?.message || error.message);
    throw error;
  }
};

/// **Evaluation Forms APIs**
export const createEvaluationForm = async (formData) => {
  try {
    const response = await api.post('/evaluation-forms', formData);
    return response.data;
  } catch (error) {
    console.error('Create Evaluation Form Error:', error.response?.data?.message || error.message);
    throw error;
  }
};

export const getEvaluationForms = async () => {
  try {
    const response = await api.get('/evaluation-forms');
    return response.data;
  } catch (error) {
    console.error('Get Evaluation Forms Error:', error.response?.data?.message || error.message);
    throw error;
  }
};

export const getEvaluationFormById = async (id) => {
  try {
    const response = await api.get(`/evaluation-forms/${id}`);
    return response.data;
  } catch (error) {
    console.error('Get Evaluation Form By ID Error:', error.response?.data?.message || error.message);
    throw error;
  }
};

export const updateEvaluationForm = async (id, formData) => {
  try {
    const response = await api.put(`/evaluation-forms/${id}`, formData);
    return response.data;
  } catch (error) {
    console.error('Update Evaluation Form Error:', error.response?.data?.message || error.message);
    throw error;
  }
};

export const deleteEvaluationForm = async (id) => {
  try {
    const response = await api.delete(`/evaluation-forms/${id}`);
    return response.data;
  } catch (error) {
    console.error('Delete Evaluation Form Error:', error.response?.data?.message || error.message);
    throw error;
  }
};

/// **Evaluations APIs**
export const createEvaluation = async (evaluationData) => {
  try {
    const response = await api.post('/evaluations', evaluationData);
    return response.data;
  } catch (error) {
    console.error('Create Evaluation Error:', error.response?.data?.message || error.message);
    throw error;
  }
};

export const submitEvaluation = async (evaluationData) => {
  try {
    const response = await api.post('/evaluations/submit', evaluationData);
    return response.data;
  } catch (error) {
    console.error('Submit Evaluation Error:', error.response?.data?.message || error.message);
    throw error;
  }
};

export const getPendingEvaluations = async () => {
  try {
    const response = await api.get('/evaluations/pending');
    return response.data;
  } catch (error) {
    console.error('Get Pending Evaluations Error:', error.response?.data?.message || error.message);
    throw error;
  }
};

export const getEvaluationById = async (id) => {
  try {
    const response = await api.get(`/evaluations/${id}`);
    return response.data;
  } catch (error) {
    console.error('Get Evaluation By ID Error:', error.response?.data?.message || error.message);
    throw error;
  }
};

export default api;
