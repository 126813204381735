import React, { useState, useCallback } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";
import { useAuth } from "../../contexts/AuthContext";
import { Eye, EyeOff } from "lucide-react";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "https://www.arifootball.co.th";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const { isDarkMode } = useTheme();

  const from = location.state?.from?.pathname || "/dashboard";

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!email || !password) {
        setError("กรุณากรอกอีเมลและรหัสผ่าน");
        return;
      }

      setError(""); // เคลียร์ error ก่อนเริ่มโหลด
      setIsLoading(true);

      try {
        const response = await axios.post(
          `${API_URL}/users/login`,
          { email, password },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // ตรวจสอบว่า token มีค่าจริงหรือไม่
        if (response.data.token) {
          // เก็บ token ลง localStorage
          localStorage.setItem("token", response.data.token);

          // จัดการ state ของผู้ใช้
          login(response.data);

          // นำผู้ใช้ไปยังหน้า Dashboard หรือเส้นทางที่ระบุไว้
          navigate(from, { replace: true });
        } else {
          throw new Error("เข้าสู่ระบบล้มเหลว: Token ไม่ถูกต้อง");
        }
      } catch (err) {
        console.error("Login Error:", err);
        if (err.response) {
          // ใช้ข้อความ error จาก backend
          setError(err.response.data.message || "เข้าสู่ระบบล้มเหลว โปรดลองอีกครั้ง");
        } else {
          setError("เกิดข้อผิดพลาดที่ไม่คาดคิด โปรดลองอีกครั้ง");
        }
      } finally {
        setIsLoading(false); // ปิดสถานะการโหลด
      }
    },
    [email, password, login, navigate, from]
  );

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  return (
    <div
      className={`min-h-screen flex items-center justify-center ${
        isDarkMode ? "bg-gray-900" : "bg-gray-100"
      }`}
    >
      <div
        className={`max-w-md w-full space-y-8 p-10 ${
          isDarkMode ? "bg-gray-800" : "bg-white"
        } rounded-xl shadow-md`}
      >
        <div>
          <h2
            className={`mt-6 text-center text-3xl font-extrabold ${
              isDarkMode ? "text-white" : "text-gray-900"
            }`}
          >
            เข้าสู่ระบบ
          </h2>
        </div>
        {error && <div className="text-red-500 text-center">{error}</div>}
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="sr-only">
                อีเมล
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`appearance-none rounded-none relative block w-full px-3 py-2 border ${
                  isDarkMode
                    ? "border-gray-700 bg-gray-700 text-white"
                    : "border-gray-300 text-gray-900"
                } placeholder-gray-500 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="อีเมล"
              />
            </div>
            <div className="relative">
              <label htmlFor="password" className="sr-only">
                รหัสผ่าน
              </label>
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`appearance-none rounded-none relative block w-full px-3 py-2 border ${
                  isDarkMode
                    ? "border-gray-700 bg-gray-700 text-white"
                    : "border-gray-300 text-gray-900"
                } placeholder-gray-500 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                placeholder="รหัสผ่าน"
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={togglePasswordVisibility}
                aria-label="Toggle password visibility"
              >
                {showPassword ? (
                  <EyeOff
                    className={`h-5 w-5 ${
                      isDarkMode ? "text-gray-300" : "text-gray-400"
                    }`}
                  />
                ) : (
                  <Eye
                    className={`h-5 w-5 ${
                      isDarkMode ? "text-gray-300" : "text-gray-400"
                    }`}
                  />
                )}
              </button>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link
                to="/register"
                className={`font-medium ${
                  isDarkMode
                    ? "text-indigo-400"
                    : "text-indigo-600 hover:text-indigo-500"
                }`}
              >
                ยังไม่มีบัญชี? ลงทะเบียน
              </Link>
            </div>
            <div className="text-sm">
              <Link
                to="/forgot-password"
                className={`font-medium ${
                  isDarkMode
                    ? "text-indigo-400"
                    : "text-indigo-600 hover:text-indigo-500"
                }`}
              >
                ลืมรหัสผ่าน?
              </Link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                isDarkMode
                  ? "bg-indigo-600 hover:bg-indigo-700"
                  : "bg-indigo-600 hover:bg-indigo-700"
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? "กำลังเข้าสู่ระบบ..." : "เข้าสู่ระบบ"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
