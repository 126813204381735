import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import DashboardLayout from './components/layout/DashboardLayout';
import { BarChart, Users, ShoppingCart, DollarSign, Settings } from 'lucide-react';
import Home from './components/Home';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ForgotPassword from './components/auth/ForgotPassword';
import StatCard from './components/dashboard/StatCard';
import SalesChart from './components/dashboard/SalesChart';
import RecentOrders from './components/dashboard/RecentOrders';
import NotificationCenter from './components/dashboard/NotificationCenter';
import UserManagement from './components/dashboard/UserManagement';
import OrgStructure from './components/organization/OrgStructure';
import JobPositions from './components/organization/JobPositions';
import EmployeeInfo from './components/organization/EmployeeInfo';
import EvaluationFormList from './components/evaluation/EvaluationFormList';
import EvaluationFormEditor from './components/evaluation/EvaluationFormEditor';
import PendingEvaluations from './components/evaluation/PendingEvaluations';
import './styles/tailwind.css';
import SettingsPage from './components/settings/SettingsPage';
import UserProfile from './components/user/UserProfile';
import EditProfile from './components/user/EditProfile';
import ChangePassword from './components/user/ChangePassword';

function App() {
  const [user, setUser] = useState(null);

  const handleLogin = (userData) => {
    setUser(userData);
  };

  const handleLogout = () => {
    setUser(null);
  };

  const ProtectedRoute = ({ children }) => {
    if (!user) {
      return <Navigate to="/login" replace />;
    }
    return <DashboardLayout onLogout={handleLogout}>{children}</DashboardLayout>;
  };

  const DashboardContent = () => (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <StatCard icon={BarChart} title="Total Sales" value="$54,321" bgColor="bg-blue-500" />
        <StatCard icon={Users} title="Total Users" value="12,345" bgColor="bg-green-500" />
        <StatCard icon={ShoppingCart} title="Orders" value="1,234" bgColor="bg-yellow-500" />
        <StatCard icon={DollarSign} title="Revenue" value="$12,345" bgColor="bg-red-500" />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        <SalesChart />
        <NotificationCenter />
      </div>
      <RecentOrders />
      <div className="mt-8">
        <UserManagement />
      </div>
    </>
  );

  return (
    <AuthProvider>
      <ThemeProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            
            <Route path="/dashboard" element={<ProtectedRoute><DashboardContent /></ProtectedRoute>} />
            <Route path="/org-structure" element={<ProtectedRoute><OrgStructure /></ProtectedRoute>} />
            <Route path="/employee-info" element={<ProtectedRoute><EmployeeInfo /></ProtectedRoute>} />
            <Route path="/job-positions" element={<ProtectedRoute><JobPositions /></ProtectedRoute>} />
            <Route path="/evaluation-forms" element={<ProtectedRoute><EvaluationFormList /></ProtectedRoute>} />
            <Route path="/evaluation-forms/create" element={<ProtectedRoute><EvaluationFormEditor /></ProtectedRoute>} />
            <Route path="/evaluation-forms/edit/:id" element={<ProtectedRoute><EvaluationFormEditor /></ProtectedRoute>} />
            <Route path="/pending-evaluations" element={<ProtectedRoute><PendingEvaluations /></ProtectedRoute>} />
            <Route path="/settings" element={<ProtectedRoute><SettingsPage /></ProtectedRoute>} />
            <Route path="/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
            <Route path="/profile/edit" element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />
            <Route path="/profile/change-password" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
            
            {/* Catch all route for undefined paths */}
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
