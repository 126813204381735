import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';

const Home = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`min-h-screen flex flex-col ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}> 
      <header className="flex-shrink-0 py-6"> 
        <div className="container mx-auto px-4">
          <nav className="flex justify-between items-center">
            <h1 className="text-2xl font-bold">Your Company</h1>
            <div>
              <Link to="/login" className="mr-4 hover:text-blue-500">Login</Link>
              <Link to="/register" className="hover:text-blue-500">Register</Link>
            </div>
          </nav>
        </div>
      </header>

      <main className="flex-grow flex items-center justify-center"> 
        <div className="max-w-xl text-center"> 
          <h2 className="text-4xl font-bold mb-4">Welcome to Our Platform</h2>
          <p className="text-xl mb-8">Discover amazing features and boost your productivity.</p>
          <Link 
            to="/register" 
            className={`inline-block py-3 px-6 rounded-lg ${
              isDarkMode 
                ? 'bg-blue-600 hover:bg-blue-700' 
                : 'bg-blue-500 hover:bg-blue-600'
            } text-white font-semibold`}
          >
            Get Started
          </Link>
        </div>
      </main>

      <footer className={`flex-shrink-0 py-6 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-200'}`}>
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 Your Company. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Home;